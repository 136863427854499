const SELECTORS = {
    bacodeBar: '[data-js-barcode]',
    searchBtn: '[data-js-search]',
    searchErr: '[data-js-error]',
};

const CLASSES = {
    searchErrorVisibile: 's-form__searcherror--visible',
};

const elems = {};

const redirect = (code) => {
    window.location = `${window.location.origin}/${code}${window.location.search}`;
};

const checkCode = (code) => {
    return /^\d{12}$/.test(code);
};

const hideError = (elem) => {
    elem.classList.remove(CLASSES.searchErrorVisibile);
};

const showError = (elem) => {
    elem.classList.add(CLASSES.searchErrorVisibile);
};

const parentMessageHeight = (height)=>{
    window.parent.postMessage({
        message:'track-lorant-frame',
        height: height
    },'*');
};

const init = () => {

    const bodyEl = document.querySelector('body');
    const hBody = bodyEl.scrollHeight;
    const mq = window.matchMedia('(max-width:768px)');
    parentMessageHeight(hBody);
   mq.addEventListener('change',(ev)=>{
       parentMessageHeight(hBody);
   });

    const barcodeBar = document.querySelector(SELECTORS.bacodeBar);
    const searchBtn = document.querySelector(SELECTORS.searchBtn);
    const searchErr = document.querySelector(SELECTORS.searchErr);
    if (!barcodeBar || ! searchBtn || !searchErr) return;

    const handler = () => {
        hideError(searchErr);
        if (checkCode(barcodeBar.value)) {
            redirect(barcodeBar.value);
        } else {
            showError(searchErr);
        }
    };

    searchBtn.addEventListener('click', handler);
    barcodeBar.addEventListener('keydown', (evt) => {
        const code = (evt.keyCode ? evt.keyCode : evt.which);
        if (code == 13) { //Enter keycode
            handler();
        }
    });
}

window.addEventListener('load',init);
